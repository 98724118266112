
import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { GatsbyImage } from "gatsby-plugin-image"
import Seo from "../components/Seo";

const ContactPage = ({data}) => {
    const MySwal = withReactContent(Swal)
    const {wpPage: page} = data;
    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }

    function renderBg() {
        return (
            <h1 className="page-heading">{page.acf_contact.title}</h1>
        )
    }

    function renderImage(image, alt) {
        if (!image) {
            return null;
        }

        return (
            <GatsbyImage alt="" image={image.localFile.childImageSharp.gatsbyImageData}/>
        )
    }


    return (
        <Layout>
            <Seo seo={page.seo} />
            {renderBg()}
            <div className="bg-white">
                <div className="container w-5/6 lg:w-full">
                    <div className="pt-20 text-lg">
                        <div className="grid grid-cols-1 text-center">
                            <div>
                                <h2 className="text-2xl font-medium mb-3" id="contactForm">
                                    {page.acf_contact.titleBlock1}
                                </h2>
                                <div dangerouslySetInnerHTML={{__html: page.acf_contact.textBlock1}}/>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 my-10">
                        <div className="pb-20 px-20 mt-5 hidden lg:block">
                            {renderImage(page.acf_contact.imageBlock1, page.acf_contact.titleBlock1)}
                        </div>
                        <div className="form lg:w-full">
                            <Formik
                                initialValues={{
                                    naam: '',
                                    email: '',
                                    tel: '',
                                    interesse: '',
                                    bericht: '',
                                    botfield: '',
                                }}
                                onSubmit={
                                    (values, actions) => {
                                        fetch("/", {
                                            method: "POST",
                                            headers: { "Content-Type": "application/x-www-form-urlencoded" },
                                            body: encode({ "form-name": "contact", ...values })
                                        })
                                            .then(() => {
                                                actions.resetForm()
                                                MySwal.fire({
                                                    title: <strong>Bedankt voor je aanvraag!</strong>,
                                                    footer: 'Vita Belle Coaching',
                                                    html: 'Ik neem spoedig contact met je op',
                                                    confirmButtonText: 'Sluiten'
                                                });
                                            })
                                            .catch(() => {
                                            })
                                            .finally(() => actions.setSubmitting(false))
                                    }
                                }
                                validate={values => {
                                    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                                    const errors = {};

                                    if (values.botfield) {
                                        errors.botfield = 'you are a bot';
                                    }

                                    if(!values.naam) {
                                        errors.naam = 'Naam is verplicht'
                                    }
                                    if(!values.email || !emailRegex.test(values.email)) {
                                        errors.email = 'Een valide e-mailadres is verplicht'
                                    }
                                    return errors;
                                }}
                            >
                                {() => (
                                    <Form method="post" name="contact" data-netlify={true} netlify-honeypot="botfield">
                                        <input type="hidden" name="form-name" value="contact" />
                                        <div className="text-left">
                                            <label htmlFor="naam"
                                                   className="block uppercase text-sm text-gray-600 font-bold">Naam </label>
                                            <Field name="naam" as="input" type="text"/>
                                            <div className="text-red-500 italic">
                                                <ErrorMessage name="naam" />
                                            </div>
                                        </div>
                                        <div className="text-left">
                                            <label htmlFor="email"
                                                   className="block uppercase text-sm text-gray-600 font-bold">E-mailadres </label>
                                            <Field name="email" type="email"/>
                                            <div className="text-red-500 italic">
                                                <ErrorMessage name="email" />
                                            </div>
                                        </div>
                                        <div className="text-left">
                                            <label htmlFor="tel"
                                                   className="block uppercase text-sm text-gray-600 font-bold">Telefoonnummer </label>
                                            <Field name="tel" as="input" type="text"/>
                                        </div>
                                        <div className="text-left">
                                            <label htmlFor="interesse"
                                                   className="block uppercase text-sm text-gray-600 font-bold">Ik ben geïnteresseerd in</label>
                                            <Field name="interesse" as="select">
                                                <option value="Gratis kennismaking">Gratis kennismaking</option>
                                                <option value="Coachingstraject 3 maanden online coaching">Coachingstraject 3 maanden online coaching</option>
                                                <option value="Coachingstraject 6 maanden online coaching">Coachingstraject 6 maanden online coaching</option>
                                                <option value="Voedingsadvies op maat">Voedingsadvies op maat</option>
                                                <option value="Ik heb een vraag / wil teruggebeld worden">Ik heb een vraag / wil teruggebeld worden</option>
                                            </Field>
                                        </div>
                                        <div className="text-left">
                                            <label htmlFor="bericht"
                                                   className="block uppercase text-sm text-gray-600 font-bold">Toevoegingen
                                                / opmerkingen </label>
                                            <Field name="bericht" component="textarea"/>
                                        </div>
                                        <p className="hidden">
                                            <label>
                                                Don’t fill this out if you’re human: <Field name="botfield" as="input" type="text"/>
                                            </label>
                                            <ErrorMessage name="botfield" />
                                        </p>
                                        <button type="submit">Versturen</button>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default ContactPage;

export const ContactPageQuery = graphql`
  query ContactById($id: String!) {
  wpPage(id: { eq: $id }) {
      seo {  
          canonical
          title
          metaDesc
          focuskw
                
      }
      uri
      title
      content
      acf_contact {
       textBlock1
       title
       titleBlock1
        imageBlock1 {
         id
            mediaItemUrl
            localFile {
              publicURL
                childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
      }
     
        
      }
    }
}`;
